:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

.align--center--h, .align--center {
  justify-content: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--h.responsive, .responsive.align--center {
    align-items: center;
  }
}

.align--center--v, .bookings-archive-header, .align--center {
  align-items: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--v.responsive, .responsive.bookings-archive-header, .responsive.align--center {
    justify-content: center;
  }
}

.align--center--text {
  text-align: center;
}

.align--start--v {
  align-items: flex-start;
}

.align--start--h {
  justify-content: flex-start;
}

.align--end--v {
  align-items: flex-end;
}

.align--end--h {
  justify-content: flex-end;
}

[class*=box--] {
  margin: auto;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  [class*=box--].responsive {
    max-width: none;
  }
}

.box--1 {
  max-width: 1000px;
}

.box--2 {
  max-width: 700px;
}

.box--3 {
  max-width: 310px;
}

.flex-cnt, .bookings-archive-header {
  display: flex;
  flex-direction: row;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive, .responsive.bookings-archive-header {
    flex-direction: column;
  }
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive.reverse.responsive, .responsive.reverse.responsive.bookings-archive-header {
    flex-direction: column-reverse;
  }
}
.flex-cnt.wrap--1, .wrap--1.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--1 [class*=flex-col--], .wrap--1.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(100% - 10px);
}
.flex-cnt.gap--1, .gap--1.bookings-archive-header {
  gap: 10px;
}
.flex-cnt.wrap--2, .wrap--2.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--2 [class*=flex-col--], .wrap--2.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(50% - 10px);
}
.flex-cnt.gap--2, .gap--2.bookings-archive-header {
  gap: 20px;
}
.flex-cnt.wrap--3, .wrap--3.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--3 [class*=flex-col--], .wrap--3.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(33.3333333333% - 10px);
}
.flex-cnt.gap--3, .gap--3.bookings-archive-header {
  gap: 30px;
}
.flex-cnt.wrap--4, .wrap--4.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--4 [class*=flex-col--], .wrap--4.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(25% - 10px);
}
.flex-cnt.gap--4, .gap--4.bookings-archive-header {
  gap: 40px;
}
.flex-cnt.wrap--5, .wrap--5.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--5 [class*=flex-col--], .wrap--5.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(20% - 10px);
}
.flex-cnt.gap--5, .gap--5.bookings-archive-header {
  gap: 50px;
}
.flex-cnt.wrap--6, .wrap--6.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--6 [class*=flex-col--], .wrap--6.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(16.6666666667% - 10px);
}
.flex-cnt.gap--6, .gap--6.bookings-archive-header {
  gap: 60px;
}
.flex-cnt.wrap--7, .wrap--7.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--7 [class*=flex-col--], .wrap--7.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(14.2857142857% - 10px);
}
.flex-cnt.gap--7, .gap--7.bookings-archive-header {
  gap: 70px;
}
.flex-cnt.wrap--8, .wrap--8.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--8 [class*=flex-col--], .wrap--8.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(12.5% - 10px);
}
.flex-cnt.gap--8, .gap--8.bookings-archive-header {
  gap: 80px;
}
.flex-cnt.flex-cnt--anchor-antipodes, .flex-cnt--anchor-antipodes.bookings-archive-header {
  justify-content: space-between;
}
.flex-cnt.flex-cnt--anchor-evenly, .bookings-archive-header {
  justify-content: space-evenly;
}
.flex-cnt.vertical, .vertical.bookings-archive-header {
  flex-direction: column;
}
.flex-cnt.no-stretch [class*=flex-col--]:nth-child(odd), .no-stretch.bookings-archive-header [class*=flex-col--]:nth-child(odd) {
  flex-grow: 0;
}

.flex-col--0 {
  flex: 0;
}

.divider--0 {
  height: 0px;
}

.padding--0 {
  padding: 0px;
}

.flex-col--1 {
  flex: 1;
}

.divider--1 {
  height: 10px;
}

.padding--1 {
  padding: 10px;
}

.flex-col--2 {
  flex: 2;
}

.divider--2 {
  height: 20px;
}

.padding--2 {
  padding: 20px;
}

.flex-col--3 {
  flex: 3;
}

.divider--3 {
  height: 30px;
}

.padding--3 {
  padding: 30px;
}

.flex-col--4 {
  flex: 4;
}

.divider--4 {
  height: 40px;
}

.padding--4 {
  padding: 40px;
}

.flex-col--5 {
  flex: 5;
}

.divider--5 {
  height: 50px;
}

.padding--5 {
  padding: 50px;
}

.flex-col--6 {
  flex: 6;
}

.divider--6 {
  height: 60px;
}

.padding--6 {
  padding: 60px;
}

.flex-col--7 {
  flex: 7;
}

.divider--7 {
  height: 70px;
}

.padding--7 {
  padding: 70px;
}

.flex-col--8 {
  flex: 8;
}

.divider--8 {
  height: 80px;
}

.padding--8 {
  padding: 80px;
}

.flex-col--9 {
  flex: 9;
}

.divider--9 {
  height: 90px;
}

.padding--9 {
  padding: 90px;
}

.flex-col--10 {
  flex: 10;
}

.divider--10 {
  height: 100px;
}

.padding--10 {
  padding: 100px;
}

.divider--05 {
  height: 5px;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.display--ib {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--uppercase {
  text-transform: uppercase;
}

@media (min-width: var(--cssvar-breakpoint)) {
  .screen--mobile {
    display: none !important;
  }
}

@media (max-width: var(--cssvar-breakpoint)-1px) {
  .screen--tablet-desktop {
    display: none !important;
  }
}

/* === LAYERING === */
.z--bottom {
  z-index: 1;
}

.z--sleeve {
  z-index: 2;
}

.z--emphasis {
  z-index: 333;
}

.z--front {
  z-index: 666;
}

.z--top {
  z-index: 999;
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.3019607843);
}

.mirror {
  transform: scale(-1);
}

.back-icon {
  height: 30px;
}
.back-icon.light-dark {
  filter: brightness(0.83);
}
.back-icon.dark {
  filter: brightness(0.3);
}

.fg--black {
  color: #555555;
}

.bg--black {
  background-color: #555555;
}

.fg--white {
  color: #fff;
}

.bg--white {
  background-color: #fff;
}

.fg--primary {
  color: #e20613;
}

.bg--primary {
  background-color: #e20613;
}

.fg--secondary {
  color: #7e060d;
}

.bg--secondary {
  background-color: #7e060d;
}

.fg--lightGray {
  color: #b5b5b5;
}

.bg--lightGray {
  background-color: #b5b5b5;
}

.fg--xLightGray {
  color: #ddd;
}

.bg--xLightGray, .elenco_soste-effet .bookings-archive-item {
  background-color: #ddd;
}

.fg--xxLightGray {
  color: #f2f2f2;
}

.bg--xxLightGray, .elenco_soste-prog .bookings-archive-item, .bookings-archive-header {
  background-color: #f2f2f2;
}

.fg--gray {
  color: #828282;
}

.bg--gray {
  background-color: #828282;
}

.text--xbold {
  font-weight: 700;
}

.text--semibold {
  font-weight: 500;
}

.text--normal {
  font-weight: 400;
}

.text--distracting {
  font-size: 30px;
}

.text--important {
  font-size: 26px;
}

.text--emphasis {
  font-size: 24px;
}

.text--emphasis-2 {
  font-size: 22px;
}

.text--base {
  font-size: 16px;
}

.text--small, .bookings-archive-header {
  font-size: 14px;
}

.text--micro {
  font-size: 12px;
}

.text--emphasis-3 {
  font-size: 18px;
}

.text--bold, .bookings-archive-header {
  font-weight: 700;
}

.bookings-archive-item.active {
  border: 1px solid #828282;
}
.bookings-archive-item.disabled {
  opacity: 0.3;
}

.bookings-archive-header {
  height: 50px;
}

.position--relative {
  position: relative;
}

.full-height {
  height: 100vh;
}

.bookings-archive-item {
  transition: all 0.08s ease-in-out;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
  opacity: 1;
}
.bookings-archive-item:nth-child(1) {
  transition-delay: 0.025s;
}
.loading .bookings-archive-item:nth-child(1) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.025s;
  opacity: 0;
}
.bookings-archive-item:nth-child(2) {
  transition-delay: 0.05s;
}
.loading .bookings-archive-item:nth-child(2) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.05s;
  opacity: 0;
}
.bookings-archive-item:nth-child(3) {
  transition-delay: 0.075s;
}
.loading .bookings-archive-item:nth-child(3) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.075s;
  opacity: 0;
}
.bookings-archive-item:nth-child(4) {
  transition-delay: 0.1s;
}
.loading .bookings-archive-item:nth-child(4) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.1s;
  opacity: 0;
}
.bookings-archive-item:nth-child(5) {
  transition-delay: 0.125s;
}
.loading .bookings-archive-item:nth-child(5) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.125s;
  opacity: 0;
}
.bookings-archive-item:nth-child(6) {
  transition-delay: 0.15s;
}
.loading .bookings-archive-item:nth-child(6) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.15s;
  opacity: 0;
}
.bookings-archive-item:nth-child(7) {
  transition-delay: 0.175s;
}
.loading .bookings-archive-item:nth-child(7) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.175s;
  opacity: 0;
}
.bookings-archive-item:nth-child(8) {
  transition-delay: 0.2s;
}
.loading .bookings-archive-item:nth-child(8) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.2s;
  opacity: 0;
}
.bookings-archive-item:nth-child(9) {
  transition-delay: 0.225s;
}
.loading .bookings-archive-item:nth-child(9) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.225s;
  opacity: 0;
}
.bookings-archive-item:nth-child(10) {
  transition-delay: 0.25s;
}
.loading .bookings-archive-item:nth-child(10) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.25s;
  opacity: 0;
}
.bookings-archive-item:nth-child(11) {
  transition-delay: 0.275s;
}
.loading .bookings-archive-item:nth-child(11) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.275s;
  opacity: 0;
}
.bookings-archive-item:nth-child(12) {
  transition-delay: 0.3s;
}
.loading .bookings-archive-item:nth-child(12) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.3s;
  opacity: 0;
}
.bookings-archive-item:nth-child(13) {
  transition-delay: 0.325s;
}
.loading .bookings-archive-item:nth-child(13) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.325s;
  opacity: 0;
}
.bookings-archive-item:nth-child(14) {
  transition-delay: 0.35s;
}
.loading .bookings-archive-item:nth-child(14) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.35s;
  opacity: 0;
}
.bookings-archive-item:nth-child(15) {
  transition-delay: 0.375s;
}
.loading .bookings-archive-item:nth-child(15) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.375s;
  opacity: 0;
}
.bookings-archive-item:nth-child(16) {
  transition-delay: 0.4s;
}
.loading .bookings-archive-item:nth-child(16) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.4s;
  opacity: 0;
}
.bookings-archive-item:nth-child(17) {
  transition-delay: 0.425s;
}
.loading .bookings-archive-item:nth-child(17) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.425s;
  opacity: 0;
}
.bookings-archive-item:nth-child(18) {
  transition-delay: 0.45s;
}
.loading .bookings-archive-item:nth-child(18) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.45s;
  opacity: 0;
}
.bookings-archive-item:nth-child(19) {
  transition-delay: 0.475s;
}
.loading .bookings-archive-item:nth-child(19) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.475s;
  opacity: 0;
}
.bookings-archive-item:nth-child(20) {
  transition-delay: 0.5s;
}
.loading .bookings-archive-item:nth-child(20) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.5s;
  opacity: 0;
}
.bookings-archive-item:nth-child(21) {
  transition-delay: 0.525s;
}
.loading .bookings-archive-item:nth-child(21) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.525s;
  opacity: 0;
}
.bookings-archive-item:nth-child(22) {
  transition-delay: 0.55s;
}
.loading .bookings-archive-item:nth-child(22) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.55s;
  opacity: 0;
}
.bookings-archive-item:nth-child(23) {
  transition-delay: 0.575s;
}
.loading .bookings-archive-item:nth-child(23) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.575s;
  opacity: 0;
}
.bookings-archive-item:nth-child(24) {
  transition-delay: 0.6s;
}
.loading .bookings-archive-item:nth-child(24) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.6s;
  opacity: 0;
}
.bookings-archive-item:nth-child(25) {
  transition-delay: 0.625s;
}
.loading .bookings-archive-item:nth-child(25) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.625s;
  opacity: 0;
}
.bookings-archive-item:nth-child(26) {
  transition-delay: 0.65s;
}
.loading .bookings-archive-item:nth-child(26) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.65s;
  opacity: 0;
}
.bookings-archive-item:nth-child(27) {
  transition-delay: 0.675s;
}
.loading .bookings-archive-item:nth-child(27) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.675s;
  opacity: 0;
}
.bookings-archive-item:nth-child(28) {
  transition-delay: 0.7s;
}
.loading .bookings-archive-item:nth-child(28) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.7s;
  opacity: 0;
}
.bookings-archive-item:nth-child(29) {
  transition-delay: 0.725s;
}
.loading .bookings-archive-item:nth-child(29) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.725s;
  opacity: 0;
}
.bookings-archive-item:nth-child(30) {
  transition-delay: 0.75s;
}
.loading .bookings-archive-item:nth-child(30) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.75s;
  opacity: 0;
}
.bookings-archive-item:nth-child(31) {
  transition-delay: 0.775s;
}
.loading .bookings-archive-item:nth-child(31) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.775s;
  opacity: 0;
}
.bookings-archive-item:nth-child(32) {
  transition-delay: 0.8s;
}
.loading .bookings-archive-item:nth-child(32) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.8s;
  opacity: 0;
}
.bookings-archive-item:nth-child(33) {
  transition-delay: 0.825s;
}
.loading .bookings-archive-item:nth-child(33) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.825s;
  opacity: 0;
}
.bookings-archive-item:nth-child(34) {
  transition-delay: 0.85s;
}
.loading .bookings-archive-item:nth-child(34) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.85s;
  opacity: 0;
}
.bookings-archive-item:nth-child(35) {
  transition-delay: 0.875s;
}
.loading .bookings-archive-item:nth-child(35) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.875s;
  opacity: 0;
}
.bookings-archive-item:nth-child(36) {
  transition-delay: 0.9s;
}
.loading .bookings-archive-item:nth-child(36) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.9s;
  opacity: 0;
}
.bookings-archive-item:nth-child(37) {
  transition-delay: 0.925s;
}
.loading .bookings-archive-item:nth-child(37) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.925s;
  opacity: 0;
}
.bookings-archive-item:nth-child(38) {
  transition-delay: 0.95s;
}
.loading .bookings-archive-item:nth-child(38) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.95s;
  opacity: 0;
}
.bookings-archive-item:nth-child(39) {
  transition-delay: 0.975s;
}
.loading .bookings-archive-item:nth-child(39) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 0.975s;
  opacity: 0;
}
.bookings-archive-item:nth-child(40) {
  transition-delay: 1s;
}
.loading .bookings-archive-item:nth-child(40) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1s;
  opacity: 0;
}
.bookings-archive-item:nth-child(41) {
  transition-delay: 1.025s;
}
.loading .bookings-archive-item:nth-child(41) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1.025s;
  opacity: 0;
}
.bookings-archive-item:nth-child(42) {
  transition-delay: 1.05s;
}
.loading .bookings-archive-item:nth-child(42) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1.05s;
  opacity: 0;
}
.bookings-archive-item:nth-child(43) {
  transition-delay: 1.075s;
}
.loading .bookings-archive-item:nth-child(43) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1.075s;
  opacity: 0;
}
.bookings-archive-item:nth-child(44) {
  transition-delay: 1.1s;
}
.loading .bookings-archive-item:nth-child(44) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1.1s;
  opacity: 0;
}
.bookings-archive-item:nth-child(45) {
  transition-delay: 1.125s;
}
.loading .bookings-archive-item:nth-child(45) {
  transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
  transition-delay: 1.125s;
  opacity: 0;
}
#loader-gif {
  width: 80px;
}

