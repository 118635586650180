@use "sass:math";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

#main-menu {
  @include mainMenu;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

#main-menu__buttons-group {
  @include mainMenu;
  position: relative;
  bottom: toCSSVar(mainMenuHeightHalf);
  @include sausage;

  a:nth-child(1) img,
  a:nth-child(3) img {
    height: toCSSVar(menuIconHeight);
    position: relative;
    top: 10px;

    filter: none;
    &.active {
      // filter: invert(8%) sepia(68%) saturate(5853%) hue-rotate(363deg)
      //   brightness(89%) contrast(101%);
      filter: invert(13%) sepia(47%) saturate(6638%) hue-rotate(339deg) brightness(85%) contrast(113%);
    }
  }
  a:nth-child(1) img {
    left: 20px;
  }
  a:nth-child(3) img {
    right: 20px;
  }
  a:nth-child(2) img {
    height: toCSSVar(menuIconHeightDouble);

    filter: invert(30%) sepia(1%) saturate(0%) hue-rotate(350deg)
      brightness(80%) contrast(81%);
    &.active {
      filter: none;
    }
  }
}

#main-menu__sleeve {
  @include mainMenu;
  @include circle(toCSSVar(mainMenuFullHeight));
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
a.menu-account,
a.menu-soste {
  padding-top: 5px;
}

#ombra_menu {
  background: rgba(30, 72, 82, 0);
  background: -moz-linear-gradient(
    top,
    rgba(30, 72, 82, 0) 0%,
    rgba(203, 203, 203, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(30, 72, 82, 0)),
    color-stop(100%, rgba(203, 203, 203, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(30, 72, 82, 0) 0%,
    rgba(203, 203, 203, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(30, 72, 82, 0) 0%,
    rgba(203, 203, 203, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(30, 72, 82, 0) 0%,
    rgba(203, 203, 203, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(30, 72, 82, 0) 0%,
    rgba(203, 203, 203, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e4852', endColorstr='#8a8a8a', GradientType=0 );
  position: fixed;
  bottom: toCSSVar(mainMenuHeight);
  z-index: -1;
  width: 100vw;
  height: 15vh;
  pointer-events: none;
}

a.menu-prenota:after {
  content: "";
  position: absolute;
  display: grid;
  overflow: hidden;
  top: 13px;
  border-radius: 50px;
  background-color: #f2f2f2;
  background-position: 40% 60%;
  background-image: url(/src/assets/imgs/menu-cronometro.svg);
  width: 28px;
  height: 28px;
  margin: -2px 0px 0 5px;
}

a.menu-prenota.rotate-center:after {
  -webkit-animation: rotate-center 8s ease-in-out 5s infinite;
  animation: rotate-center 8s ease-in-out 5s infinite;
}
/* -------ANIMAZIONE - rotazione cronometro---------- */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
