:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

.align--center--h, .align--center {
  justify-content: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--h.responsive, .responsive.align--center {
    align-items: center;
  }
}

.align--center--v, .bookings-archive-header, .align--center {
  align-items: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--v.responsive, .responsive.bookings-archive-header, .responsive.align--center {
    justify-content: center;
  }
}

.align--center--text {
  text-align: center;
}

.align--start--v {
  align-items: flex-start;
}

.align--start--h {
  justify-content: flex-start;
}

.align--end--v {
  align-items: flex-end;
}

.align--end--h {
  justify-content: flex-end;
}

[class*=box--] {
  margin: auto;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  [class*=box--].responsive {
    max-width: none;
  }
}

.box--1 {
  max-width: 1000px;
}

.box--2 {
  max-width: 700px;
}

.box--3 {
  max-width: 310px;
}

.flex-cnt, .bookings-archive-header {
  display: flex;
  flex-direction: row;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive, .responsive.bookings-archive-header {
    flex-direction: column;
  }
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive.reverse.responsive, .responsive.reverse.responsive.bookings-archive-header {
    flex-direction: column-reverse;
  }
}
.flex-cnt.wrap--1, .wrap--1.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--1 [class*=flex-col--], .wrap--1.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(100% - 10px);
}
.flex-cnt.gap--1, .gap--1.bookings-archive-header {
  gap: 10px;
}
.flex-cnt.wrap--2, .wrap--2.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--2 [class*=flex-col--], .wrap--2.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(50% - 10px);
}
.flex-cnt.gap--2, .gap--2.bookings-archive-header {
  gap: 20px;
}
.flex-cnt.wrap--3, .wrap--3.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--3 [class*=flex-col--], .wrap--3.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(33.3333333333% - 10px);
}
.flex-cnt.gap--3, .gap--3.bookings-archive-header {
  gap: 30px;
}
.flex-cnt.wrap--4, .wrap--4.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--4 [class*=flex-col--], .wrap--4.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(25% - 10px);
}
.flex-cnt.gap--4, .gap--4.bookings-archive-header {
  gap: 40px;
}
.flex-cnt.wrap--5, .wrap--5.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--5 [class*=flex-col--], .wrap--5.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(20% - 10px);
}
.flex-cnt.gap--5, .gap--5.bookings-archive-header {
  gap: 50px;
}
.flex-cnt.wrap--6, .wrap--6.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--6 [class*=flex-col--], .wrap--6.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(16.6666666667% - 10px);
}
.flex-cnt.gap--6, .gap--6.bookings-archive-header {
  gap: 60px;
}
.flex-cnt.wrap--7, .wrap--7.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--7 [class*=flex-col--], .wrap--7.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(14.2857142857% - 10px);
}
.flex-cnt.gap--7, .gap--7.bookings-archive-header {
  gap: 70px;
}
.flex-cnt.wrap--8, .wrap--8.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--8 [class*=flex-col--], .wrap--8.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(12.5% - 10px);
}
.flex-cnt.gap--8, .gap--8.bookings-archive-header {
  gap: 80px;
}
.flex-cnt.flex-cnt--anchor-antipodes, .flex-cnt--anchor-antipodes.bookings-archive-header {
  justify-content: space-between;
}
.flex-cnt.flex-cnt--anchor-evenly, .bookings-archive-header {
  justify-content: space-evenly;
}
.flex-cnt.vertical, .vertical.bookings-archive-header {
  flex-direction: column;
}
.flex-cnt.no-stretch [class*=flex-col--]:nth-child(odd), .no-stretch.bookings-archive-header [class*=flex-col--]:nth-child(odd) {
  flex-grow: 0;
}

.flex-col--0 {
  flex: 0;
}

.divider--0 {
  height: 0px;
}

.padding--0 {
  padding: 0px;
}

.flex-col--1 {
  flex: 1;
}

.divider--1 {
  height: 10px;
}

.padding--1 {
  padding: 10px;
}

.flex-col--2 {
  flex: 2;
}

.divider--2 {
  height: 20px;
}

.padding--2 {
  padding: 20px;
}

.flex-col--3 {
  flex: 3;
}

.divider--3 {
  height: 30px;
}

.padding--3 {
  padding: 30px;
}

.flex-col--4 {
  flex: 4;
}

.divider--4 {
  height: 40px;
}

.padding--4 {
  padding: 40px;
}

.flex-col--5 {
  flex: 5;
}

.divider--5 {
  height: 50px;
}

.padding--5 {
  padding: 50px;
}

.flex-col--6 {
  flex: 6;
}

.divider--6 {
  height: 60px;
}

.padding--6 {
  padding: 60px;
}

.flex-col--7 {
  flex: 7;
}

.divider--7 {
  height: 70px;
}

.padding--7 {
  padding: 70px;
}

.flex-col--8 {
  flex: 8;
}

.divider--8 {
  height: 80px;
}

.padding--8 {
  padding: 80px;
}

.flex-col--9 {
  flex: 9;
}

.divider--9 {
  height: 90px;
}

.padding--9 {
  padding: 90px;
}

.flex-col--10 {
  flex: 10;
}

.divider--10 {
  height: 100px;
}

.padding--10 {
  padding: 100px;
}

.divider--05 {
  height: 5px;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.display--ib {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--uppercase {
  text-transform: uppercase;
}

@media (min-width: var(--cssvar-breakpoint)) {
  .screen--mobile {
    display: none !important;
  }
}

@media (max-width: var(--cssvar-breakpoint)-1px) {
  .screen--tablet-desktop {
    display: none !important;
  }
}

/* === LAYERING === */
.z--bottom {
  z-index: 1;
}

.z--sleeve {
  z-index: 2;
}

.z--emphasis {
  z-index: 333;
}

.z--front {
  z-index: 666;
}

.z--top {
  z-index: 999;
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0px 15px 13px #0000004d;
}

.mirror {
  transform: scale(-1);
}

.back-icon {
  height: 30px;
}
.back-icon.light-dark {
  filter: brightness(0.83);
}
.back-icon.dark {
  filter: brightness(0.3);
}

.fg--black {
  color: #555555;
}

.bg--black {
  background-color: #555555;
}

.fg--white {
  color: #fff;
}

.bg--white {
  background-color: #fff;
}

.fg--primary {
  color: #e20613;
}

.bg--primary {
  background-color: #e20613;
}

.fg--secondary {
  color: #7e060d;
}

.bg--secondary {
  background-color: #7e060d;
}

.fg--lightGray {
  color: #b5b5b5;
}

.bg--lightGray {
  background-color: #b5b5b5;
}

.fg--xLightGray {
  color: #ddd;
}

.bg--xLightGray {
  background-color: #ddd;
}

.fg--xxLightGray {
  color: #f2f2f2;
}

.bg--xxLightGray, .bookings-archive-header {
  background-color: #f2f2f2;
}

.fg--gray {
  color: #828282;
}

.bg--gray {
  background-color: #828282;
}

.text--xbold {
  font-weight: 700;
}

.text--semibold {
  font-weight: 500;
}

.text--normal {
  font-weight: 400;
}

.text--distracting {
  font-size: 30px;
}

.text--important {
  font-size: 26px;
}

.text--emphasis {
  font-size: 24px;
}

.text--emphasis-2 {
  font-size: 22px;
}

.text--base {
  font-size: 16px;
}

.text--small, .bookings-archive-header {
  font-size: 14px;
}

.text--micro {
  font-size: 12px;
}

.text--emphasis-3 {
  font-size: 18px;
}

.text--bold, .bookings-archive-header {
  font-weight: 700;
}

.bookings-archive-item.active {
  border: 1px solid #828282;
}
.bookings-archive-item.disabled {
  opacity: 0.3;
}

.bookings-archive-header {
  height: 50px;
}

.position--relative {
  position: relative;
}

.full-height {
  height: 100vh;
}

#main-menu {
  height: var(--cssvar-mainMenuHeight);
  background-color: var(--cssvar-xxLightGray);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

#main-menu__buttons-group {
  height: var(--cssvar-mainMenuHeight);
  background-color: var(--cssvar-xxLightGray);
  position: relative;
  bottom: var(--cssvar-mainMenuHeightHalf);
  width: 100%;
  height: 55px;
  border-radius: 28px;
}
#main-menu__buttons-group a:nth-child(1) img,
#main-menu__buttons-group a:nth-child(3) img {
  height: var(--cssvar-menuIconHeight);
  position: relative;
  top: 10px;
  filter: none;
}
#main-menu__buttons-group a:nth-child(1) img.active,
#main-menu__buttons-group a:nth-child(3) img.active {
  filter: invert(13%) sepia(47%) saturate(6638%) hue-rotate(339deg) brightness(85%) contrast(113%);
}
#main-menu__buttons-group a:nth-child(1) img {
  left: 20px;
}
#main-menu__buttons-group a:nth-child(3) img {
  right: 20px;
}
#main-menu__buttons-group a:nth-child(2) img {
  height: var(--cssvar-menuIconHeightDouble);
  filter: invert(30%) sepia(1%) saturate(0%) hue-rotate(350deg) brightness(80%) contrast(81%);
}
#main-menu__buttons-group a:nth-child(2) img.active {
  filter: none;
}

#main-menu__sleeve {
  height: var(--cssvar-mainMenuHeight);
  background-color: var(--cssvar-xxLightGray);
  border-radius: 50%;
  width: var(--cssvar-mainMenuFullHeight);
  height: var(--cssvar-mainMenuFullHeight);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

a.menu-account,
a.menu-soste {
  padding-top: 5px;
}

#ombra_menu {
  background: rgba(30, 72, 82, 0);
  background: -moz-linear-gradient(top, rgba(30, 72, 82, 0) 0%, #cbcbcb 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(30, 72, 82, 0)), color-stop(100%, #cbcbcb));
  background: -webkit-linear-gradient(top, rgba(30, 72, 82, 0) 0%, #cbcbcb 100%);
  background: -o-linear-gradient(top, rgba(30, 72, 82, 0) 0%, #cbcbcb 100%);
  background: -ms-linear-gradient(top, rgba(30, 72, 82, 0) 0%, #cbcbcb 100%);
  background: linear-gradient(to bottom, rgba(30, 72, 82, 0) 0%, #cbcbcb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1e4852", endColorstr="#8a8a8a", GradientType=0 );
  position: fixed;
  bottom: var(--cssvar-mainMenuHeight);
  z-index: -1;
  width: 100vw;
  height: 15vh;
  pointer-events: none;
}

a.menu-prenota:after {
  content: "";
  position: absolute;
  display: grid;
  overflow: hidden;
  top: 13px;
  border-radius: 50px;
  background-color: #f2f2f2;
  background-position: 40% 60%;
  background-image: url(/src/assets/imgs/menu-cronometro.svg);
  width: 28px;
  height: 28px;
  margin: -2px 0px 0 5px;
}

a.menu-prenota.rotate-center:after {
  -webkit-animation: rotate-center 8s ease-in-out 5s infinite;
  animation: rotate-center 8s ease-in-out 5s infinite;
}

/* -------ANIMAZIONE - rotazione cronometro---------- */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

