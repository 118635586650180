:root {
    --cssvar-mainMenuFullHeight: 75px;  
    --cssvar-fontFamily: "Titillium Web", sans-serif;
    --cssvar-breakpoint: 1024px;
    --cssvar-gap: 10px;
    --cssvar-defaultRadius: 8px;
    --cssvar-baseFontSize: 16px;
    --cssvar-font: 400 16px "Titillium Web", sans-serif;
    --cssvar-lineHeight: 1.2;
    --cssvar-xxLightGray: #f2f2f2;
    --cssvar-xLightGray: #ddd;
    --cssvar-lightGray: #b5b5b5;
    --cssvar-gray: #828282;
    --cssvar-grayChiaro: #c9c9c9;
    --cssvar-black: #555555;
    --cssvar-white: #fff;
    --cssvar-primary: #e20613;
    --cssvar-secondary: #7e060d;
    --cssvar-mainMenuHeight: 40px;
    --cssvar-mainMenuHeightHalf: 20px;
    --cssvar-mainMenuHeightDouble: 80px;
    --cssvar-toastBottom: calc(75px + 20px);
    --cssvar-menuIconHeight: 22px;
}

$mainMenuFullHeight: 75px;  
$fontFamily: "Titillium Web", sans-serif;
$breakpoint: 1024px;
$gap: 10px;
$defaultRadius: 8px;
$baseFontSize: 16px;
$font: 400 $baseFontSize $fontFamily;
$lineHeight: 1.2;
$xxLightGray: #f2f2f2;
$xLightGray: #ddd;
$lightGray: #b5b5b5;
$gray: #828282;
$grayChiaro: #c9c9c9;
$black: #555555;
$white: #fff;
$primary: #e20613;
$secondary: #7e060d;
$mainMenuHeight: 40px;
$toastBottom: $mainMenuFullHeight + ($gap * 2);
$menuIconHeight: 22px;
