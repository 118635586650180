@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

$iconWidth: 25px;

.input-password__container {
  position: relative;
}

.input-password__input {
  padding-right: $iconWidth + 5px;
}

.input-password__icon {
  width: $iconWidth;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 7px;
}
