@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.button-icon.muted {
    @include sausage;
    background-color: #dddddd;
    color: #555555;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
    @extend .text--emphasis-3;
    @extend .text--bold;

    img {
        height: 32px;
        margin-left: toCSSVar(gap);
        position: relative;
        top: 2px;
    }
}

.booking-detail-page.map {
    min-height: 100vh;
    background: #ddd;

    #map-cnt {
        overflow: scroll;
        position: relative;
        display: flex;
        align-items: center;

        #map-content {
            position: absolute;
            padding: 0px 0px 150px;
        }
    }

    #map-buttons {
        position: fixed;
        z-index: 9999999;
        right: 25px;
        top: 50px;
    }
}
.map-button {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    background: #f2f2f2;
    box-shadow: 0 15px 13px #0000004d;

    &:nth-child(1) {
        margin-bottom: 25px;
    }

    &:nth-child(2) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:nth-child(3) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

#map-general-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0000001f;
    z-index: -1;
    backdrop-filter: blur(8px);
    transition: opacity 0.35s;
    opacity: 0;

    .open & {
        opacity: 1;
        z-index: 999;
        transition: opacity 0.35s;
    }
}
#map-general-content {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: white;
    z-index: -1;
    transform: translate3d(0, 110%, 0);
    transition: transform 0.2s ease-in-out;

    .open & {
        z-index: 999;
        transform: translate3d(0, 0, 0);
        transition: transform 0.2s ease-in-out;
    }
}

#map-overview-cnt > svg {
    width: 72%;
}

#map-legend {
    // color: var(--cssvar-primary);
    // font-size: 14px;
    // padding: 10px 0;
    font-weight: 600;
    border-top: 2px solid;
    display: inline-block;
    margin: auto;

    /* tmp */
    background: var(--cssvar-primary);
    color: var(--cssvar-white);
    padding: 10px 20px;
    font-size: 18px;
}
