@use "sass:math";
@import "./_variables";
@import "./_mixins";

.align--center--h {
  justify-content: center;
  @include responsive() {
    align-items: center;
  }
}
.align--center--v {
  align-items: center;
  @include responsive() {
    justify-content: center;
  }
}
.align--center--text {
  text-align: center;
}

.align--center {
  @extend .align--center--h;
  @extend .align--center--v;
}

.align--start--v {
  align-items: flex-start;
}
.align--start--h {
  justify-content: flex-start;
}
.align--end--v {
  align-items: flex-end;
}
.align--end--h {
  justify-content: flex-end;
}

// components
[class*="box--"] {
  margin: auto;

  @include responsive {
    max-width: none;
  }
}
.box--1 {
  max-width: 1000px;
}
.box--2 {
  max-width: 700px;
}
.box--3 {
  max-width: 310px;
}

.flex-cnt {
  display: flex;
  flex-direction: row;
  @include responsive {
    flex-direction: column;
  }
  &.responsive.reverse {
    @include responsive {
      flex-direction: column-reverse;
    }
  }
  @for $i from 1 through 8 {
    $perc: #{math.div(100, $i)}+ "%";

    &.wrap--#{$i} {
      flex-wrap: wrap;
      [class*="flex-col--"] {
        flex-grow: 1;
        flex-basis: calc($perc - $gap);
      }
    }
    &.gap--#{$i} {
      gap: $gap * $i;
    }
  }
  // @todo gestire mobile
  &.flex-cnt--anchor-antipodes {
    justify-content: space-between;
  }
  &.flex-cnt--anchor-evenly {
    justify-content: space-evenly;
  }

  &.vertical {
    flex-direction: column;
  }

  &.no-stretch {
    [class*="flex-col--"]:nth-child(odd) {
      flex-grow: 0;
    }
  }
}
@for $i from 0 through 10 {
  .flex-col--#{$i} {
    flex: $i;
  }
  .divider--#{$i} {
    height: $gap * $i;
  }
  .padding--#{$i} {
    padding: $gap * $i;
  }
}
.divider--05 {
  height: math.div($gap, 2);
}

// utils
.hidden {
  display: none !important;
}
.invisible {
  visibility: hidden !important;
}
.display--ib {
  display: inline-block;
}
.clickable {
  cursor: pointer;
}
.text--left {
  text-align: left;
}
.text--center {
  text-align: center;
}
.text--right {
  text-align: right;
}
.text--uppercase {
  text-transform: uppercase;
}

// responsive
.screen--mobile {
  @include breakUp {
    display: none !important;
  }
}
.screen--tablet-desktop {
  @include breakDown {
    display: none !important;
  }
}

/* === LAYERING === */
.z--bottom {
  z-index: 1;
}
.z--sleeve {
  z-index: 2;
}
.z--emphasis {
  z-index: 333;
}
.z--front {
  z-index: 666;
}
.z--top {
  z-index: 999;
}

.rounded {
  border-radius: $defaultRadius;
}
.shadow {
  box-shadow: 0px 15px 13px #0000004d;
}
.mirror {
  transform: scale(-1);
}
.back-icon {
  height: 30px;
  // position: absolute;

  &.light-dark {
    filter: brightness(0.83);
  }
  &.dark {
    filter: brightness(0.3);
  }
}

// themeing
$colors: (
  "black": $black,
  "white": $white,
  "primary": $primary,
  "secondary": $secondary,
  "lightGray": $lightGray,
  "xLightGray": $xLightGray,
  "xxLightGray": $xxLightGray,
  "gray": $gray,
);
@each $color, $code in $colors {
  .fg--#{$color} {
    color: $code;
  }
  .bg--#{$color} {
    background-color: $code;
  }
}

// fonts
.text--xbold {
  font-weight: 700;
}

.text--semibold {
  font-weight: 500;
}
.text--normal {
  font-weight: 400;
}

.text--distracting {
  font-size: 30px;
}
.text--important {
  font-size: 26px;
}
.text--emphasis {
  font-size: 24px;
}
.text--emphasis-2 {
  font-size: 22px;
}

.text--base {
  font-size: 16px;
}

.text--small {
  font-size: 14px;
}
.text--micro {
  font-size: 12px;
}

.text--emphasis-3 {
  font-size: 18px;
}
.text--bold {
  font-weight: 700;
}

.bookings-archive-item {
  &.active {
    border: 1px solid $gray;
  }
  &.disabled {
    opacity: 0.3;
  }
}
.bookings-archive-header {
  height: 50px;
  @extend .bg--xxLightGray;
  @extend .text--small;
  @extend .text--bold;
  @extend .flex-cnt;
  @extend .flex-cnt--anchor-evenly;
  @extend .align--center--v;
}

.position--relative {
  position: relative;
}

.full-height {
  height: 100vh;
}