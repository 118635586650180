:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

:root {
  --cssvar-mainMenuFullHeight: 75px;
  --cssvar-fontFamily: "Titillium Web", sans-serif;
  --cssvar-breakpoint: 1024px;
  --cssvar-gap: 10px;
  --cssvar-defaultRadius: 8px;
  --cssvar-baseFontSize: 16px;
  --cssvar-font: 400 16px "Titillium Web", sans-serif;
  --cssvar-lineHeight: 1.2;
  --cssvar-xxLightGray: #f2f2f2;
  --cssvar-xLightGray: #ddd;
  --cssvar-lightGray: #b5b5b5;
  --cssvar-gray: #828282;
  --cssvar-grayChiaro: #c9c9c9;
  --cssvar-black: #555555;
  --cssvar-white: #fff;
  --cssvar-primary: #e20613;
  --cssvar-secondary: #7e060d;
  --cssvar-mainMenuHeight: 40px;
  --cssvar-mainMenuHeightHalf: 20px;
  --cssvar-mainMenuHeightDouble: 80px;
  --cssvar-toastBottom: calc(75px + 20px);
  --cssvar-menuIconHeight: 22px;
}

.align--center--h, .align--center {
  justify-content: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--h.responsive, .responsive.align--center {
    align-items: center;
  }
}

.align--center--v, #book-now-header, .bookings-archive-header, .align--center {
  align-items: center;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .align--center--v.responsive, .responsive#book-now-header, .responsive.bookings-archive-header, .responsive.align--center {
    justify-content: center;
  }
}

.align--center--text {
  text-align: center;
}

.align--start--v {
  align-items: flex-start;
}

.align--start--h {
  justify-content: flex-start;
}

.align--end--v {
  align-items: flex-end;
}

.align--end--h {
  justify-content: flex-end;
}

[class*=box--] {
  margin: auto;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  [class*=box--].responsive {
    max-width: none;
  }
}

.box--1 {
  max-width: 1000px;
}

.box--2 {
  max-width: 700px;
}

.box--3 {
  max-width: 310px;
}

.flex-cnt, #book-now-header, .bookings-archive-header {
  display: flex;
  flex-direction: row;
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive, .responsive#book-now-header, .responsive.bookings-archive-header {
    flex-direction: column;
  }
}
@media (max-width: var(--cssvar-breakpoint)-1px) {
  .flex-cnt.responsive.reverse.responsive, .responsive.reverse.responsive#book-now-header, .responsive.reverse.responsive.bookings-archive-header {
    flex-direction: column-reverse;
  }
}
.flex-cnt.wrap--1, .wrap--1#book-now-header, .wrap--1.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--1 [class*=flex-col--], .wrap--1#book-now-header [class*=flex-col--], .wrap--1.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(100% - 10px);
}
.flex-cnt.gap--1, .gap--1#book-now-header, .gap--1.bookings-archive-header {
  gap: 10px;
}
.flex-cnt.wrap--2, .wrap--2#book-now-header, .wrap--2.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--2 [class*=flex-col--], .wrap--2#book-now-header [class*=flex-col--], .wrap--2.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(50% - 10px);
}
.flex-cnt.gap--2, .gap--2#book-now-header, .gap--2.bookings-archive-header {
  gap: 20px;
}
.flex-cnt.wrap--3, .wrap--3#book-now-header, .wrap--3.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--3 [class*=flex-col--], .wrap--3#book-now-header [class*=flex-col--], .wrap--3.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(33.3333333333% - 10px);
}
.flex-cnt.gap--3, .gap--3#book-now-header, .gap--3.bookings-archive-header {
  gap: 30px;
}
.flex-cnt.wrap--4, .wrap--4#book-now-header, .wrap--4.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--4 [class*=flex-col--], .wrap--4#book-now-header [class*=flex-col--], .wrap--4.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(25% - 10px);
}
.flex-cnt.gap--4, .gap--4#book-now-header, .gap--4.bookings-archive-header {
  gap: 40px;
}
.flex-cnt.wrap--5, .wrap--5#book-now-header, .wrap--5.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--5 [class*=flex-col--], .wrap--5#book-now-header [class*=flex-col--], .wrap--5.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(20% - 10px);
}
.flex-cnt.gap--5, .gap--5#book-now-header, .gap--5.bookings-archive-header {
  gap: 50px;
}
.flex-cnt.wrap--6, .wrap--6#book-now-header, .wrap--6.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--6 [class*=flex-col--], .wrap--6#book-now-header [class*=flex-col--], .wrap--6.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(16.6666666667% - 10px);
}
.flex-cnt.gap--6, .gap--6#book-now-header, .gap--6.bookings-archive-header {
  gap: 60px;
}
.flex-cnt.wrap--7, .wrap--7#book-now-header, .wrap--7.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--7 [class*=flex-col--], .wrap--7#book-now-header [class*=flex-col--], .wrap--7.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(14.2857142857% - 10px);
}
.flex-cnt.gap--7, .gap--7#book-now-header, .gap--7.bookings-archive-header {
  gap: 70px;
}
.flex-cnt.wrap--8, .wrap--8#book-now-header, .wrap--8.bookings-archive-header {
  flex-wrap: wrap;
}
.flex-cnt.wrap--8 [class*=flex-col--], .wrap--8#book-now-header [class*=flex-col--], .wrap--8.bookings-archive-header [class*=flex-col--] {
  flex-grow: 1;
  flex-basis: calc(12.5% - 10px);
}
.flex-cnt.gap--8, .gap--8#book-now-header, .gap--8.bookings-archive-header {
  gap: 80px;
}
.flex-cnt.flex-cnt--anchor-antipodes, #book-now-header, .flex-cnt--anchor-antipodes.bookings-archive-header {
  justify-content: space-between;
}
.flex-cnt.flex-cnt--anchor-evenly, .flex-cnt--anchor-evenly#book-now-header, .bookings-archive-header {
  justify-content: space-evenly;
}
.flex-cnt.vertical, .vertical#book-now-header, .vertical.bookings-archive-header {
  flex-direction: column;
}
.flex-cnt.no-stretch [class*=flex-col--]:nth-child(odd), .no-stretch#book-now-header [class*=flex-col--]:nth-child(odd), .no-stretch.bookings-archive-header [class*=flex-col--]:nth-child(odd) {
  flex-grow: 0;
}

.flex-col--0 {
  flex: 0;
}

.divider--0 {
  height: 0px;
}

.padding--0 {
  padding: 0px;
}

.flex-col--1 {
  flex: 1;
}

.divider--1 {
  height: 10px;
}

.padding--1 {
  padding: 10px;
}

.flex-col--2 {
  flex: 2;
}

.divider--2 {
  height: 20px;
}

.padding--2 {
  padding: 20px;
}

.flex-col--3 {
  flex: 3;
}

.divider--3 {
  height: 30px;
}

.padding--3 {
  padding: 30px;
}

.flex-col--4 {
  flex: 4;
}

.divider--4 {
  height: 40px;
}

.padding--4 {
  padding: 40px;
}

.flex-col--5 {
  flex: 5;
}

.divider--5 {
  height: 50px;
}

.padding--5 {
  padding: 50px;
}

.flex-col--6 {
  flex: 6;
}

.divider--6 {
  height: 60px;
}

.padding--6 {
  padding: 60px;
}

.flex-col--7 {
  flex: 7;
}

.divider--7 {
  height: 70px;
}

.padding--7 {
  padding: 70px;
}

.flex-col--8 {
  flex: 8;
}

.divider--8 {
  height: 80px;
}

.padding--8 {
  padding: 80px;
}

.flex-col--9 {
  flex: 9;
}

.divider--9 {
  height: 90px;
}

.padding--9 {
  padding: 90px;
}

.flex-col--10 {
  flex: 10;
}

.divider--10 {
  height: 100px;
}

.padding--10 {
  padding: 100px;
}

.divider--05 {
  height: 5px;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.display--ib {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--uppercase {
  text-transform: uppercase;
}

@media (min-width: var(--cssvar-breakpoint)) {
  .screen--mobile {
    display: none !important;
  }
}

@media (max-width: var(--cssvar-breakpoint)-1px) {
  .screen--tablet-desktop {
    display: none !important;
  }
}

/* === LAYERING === */
.z--bottom {
  z-index: 1;
}

.z--sleeve {
  z-index: 2;
}

.z--emphasis {
  z-index: 333;
}

.z--front {
  z-index: 666;
}

.z--top {
  z-index: 999;
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0px 15px 13px #0000004d;
}

.mirror {
  transform: scale(-1);
}

.back-icon {
  height: 30px;
}
.back-icon.light-dark {
  filter: brightness(0.83);
}
.back-icon.dark {
  filter: brightness(0.3);
}

.fg--black, .calendar-day-group__number {
  color: #555555;
}

.bg--black {
  background-color: #555555;
}

.fg--white, #book-now-header {
  color: #fff;
}

.bg--white {
  background-color: #fff;
}

.fg--primary {
  color: #e20613;
}

.bg--primary {
  background-color: #e20613;
}

.fg--secondary {
  color: #7e060d;
}

.bg--secondary {
  background-color: #7e060d;
}

.fg--lightGray, .disabled .calendar-day-group__number {
  color: #b5b5b5;
}

.bg--lightGray {
  background-color: #b5b5b5;
}

.fg--xLightGray {
  color: #ddd;
}

.bg--xLightGray {
  background-color: #ddd;
}

.fg--xxLightGray {
  color: #f2f2f2;
}

.bg--xxLightGray, .bookings-archive-header {
  background-color: #f2f2f2;
}

.fg--gray {
  color: #828282;
}

.bg--gray {
  background-color: #828282;
}

.text--xbold {
  font-weight: 700;
}

.text--semibold {
  font-weight: 500;
}

.text--normal {
  font-weight: 400;
}

.text--distracting {
  font-size: 30px;
}

.text--important {
  font-size: 26px;
}

.text--emphasis, #book-now-header {
  font-size: 24px;
}

.text--emphasis-2 {
  font-size: 22px;
}

.text--base {
  font-size: 16px;
}

.text--small, .bookings-archive-header {
  font-size: 14px;
}

.text--micro {
  font-size: 12px;
}

.text--emphasis-3, .calendar-day-group__number {
  font-size: 18px;
}

.text--bold, .bookings-archive-header {
  font-weight: 700;
}

.bookings-archive-item.active {
  border: 1px solid #828282;
}
.bookings-archive-item.disabled {
  opacity: 0.3;
}

.bookings-archive-header {
  height: 50px;
}

.position--relative {
  position: relative;
}

.full-height {
  height: 100vh;
}

.calendar-day-group {
  position: relative;
  justify-content: center;
  display: flex;
  transition: all 0.1s ease-in-out;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.calendar-day-group:nth-child(2) {
  transition-delay: 0.016s;
}
.loading .calendar-day-group:nth-child(2) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.2s;
  opacity: 0;
}
.calendar-day-group:nth-child(3) {
  transition-delay: 0.024s;
}
.loading .calendar-day-group:nth-child(3) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.3s;
  opacity: 0;
}
.calendar-day-group:nth-child(4) {
  transition-delay: 0.032s;
}
.loading .calendar-day-group:nth-child(4) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.4s;
  opacity: 0;
}
.calendar-day-group:nth-child(5) {
  transition-delay: 0.04s;
}
.loading .calendar-day-group:nth-child(5) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.5s;
  opacity: 0;
}
.calendar-day-group:nth-child(6) {
  transition-delay: 0.048s;
}
.loading .calendar-day-group:nth-child(6) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.6s;
  opacity: 0;
}
.calendar-day-group:nth-child(7) {
  transition-delay: 0.056s;
}
.loading .calendar-day-group:nth-child(7) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.7s;
  opacity: 0;
}
.calendar-day-group:nth-child(8) {
  transition-delay: 0.064s;
}
.loading .calendar-day-group:nth-child(8) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.8s;
  opacity: 0;
}
.calendar-day-group:nth-child(9) {
  transition-delay: 0.072s;
}
.loading .calendar-day-group:nth-child(9) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 0.9s;
  opacity: 0;
}
.calendar-day-group:nth-child(10) {
  transition-delay: 0.08s;
}
.loading .calendar-day-group:nth-child(10) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1s;
  opacity: 0;
}
.calendar-day-group:nth-child(11) {
  transition-delay: 0.088s;
}
.loading .calendar-day-group:nth-child(11) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.1s;
  opacity: 0;
}
.calendar-day-group:nth-child(12) {
  transition-delay: 0.096s;
}
.loading .calendar-day-group:nth-child(12) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.2s;
  opacity: 0;
}
.calendar-day-group:nth-child(13) {
  transition-delay: 0.104s;
}
.loading .calendar-day-group:nth-child(13) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.3s;
  opacity: 0;
}
.calendar-day-group:nth-child(14) {
  transition-delay: 0.112s;
}
.loading .calendar-day-group:nth-child(14) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.4s;
  opacity: 0;
}
.calendar-day-group:nth-child(15) {
  transition-delay: 0.12s;
}
.loading .calendar-day-group:nth-child(15) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.5s;
  opacity: 0;
}
.calendar-day-group:nth-child(16) {
  transition-delay: 0.128s;
}
.loading .calendar-day-group:nth-child(16) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.6s;
  opacity: 0;
}
.calendar-day-group:nth-child(17) {
  transition-delay: 0.136s;
}
.loading .calendar-day-group:nth-child(17) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.7s;
  opacity: 0;
}
.calendar-day-group:nth-child(18) {
  transition-delay: 0.144s;
}
.loading .calendar-day-group:nth-child(18) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.8s;
  opacity: 0;
}
.calendar-day-group:nth-child(19) {
  transition-delay: 0.152s;
}
.loading .calendar-day-group:nth-child(19) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 1.9s;
  opacity: 0;
}
.calendar-day-group:nth-child(20) {
  transition-delay: 0.16s;
}
.loading .calendar-day-group:nth-child(20) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2s;
  opacity: 0;
}
.calendar-day-group:nth-child(21) {
  transition-delay: 0.168s;
}
.loading .calendar-day-group:nth-child(21) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.1s;
  opacity: 0;
}
.calendar-day-group:nth-child(22) {
  transition-delay: 0.176s;
}
.loading .calendar-day-group:nth-child(22) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.2s;
  opacity: 0;
}
.calendar-day-group:nth-child(23) {
  transition-delay: 0.184s;
}
.loading .calendar-day-group:nth-child(23) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.3s;
  opacity: 0;
}
.calendar-day-group:nth-child(24) {
  transition-delay: 0.192s;
}
.loading .calendar-day-group:nth-child(24) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.4s;
  opacity: 0;
}
.calendar-day-group:nth-child(25) {
  transition-delay: 0.2s;
}
.loading .calendar-day-group:nth-child(25) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.5s;
  opacity: 0;
}
.calendar-day-group:nth-child(26) {
  transition-delay: 0.208s;
}
.loading .calendar-day-group:nth-child(26) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.6s;
  opacity: 0;
}
.calendar-day-group:nth-child(27) {
  transition-delay: 0.216s;
}
.loading .calendar-day-group:nth-child(27) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.7s;
  opacity: 0;
}
.calendar-day-group:nth-child(28) {
  transition-delay: 0.224s;
}
.loading .calendar-day-group:nth-child(28) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.8s;
  opacity: 0;
}
.calendar-day-group:nth-child(29) {
  transition-delay: 0.232s;
}
.loading .calendar-day-group:nth-child(29) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 2.9s;
  opacity: 0;
}
.calendar-day-group:nth-child(30) {
  transition-delay: 0.24s;
}
.loading .calendar-day-group:nth-child(30) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3s;
  opacity: 0;
}
.calendar-day-group:nth-child(31) {
  transition-delay: 0.248s;
}
.loading .calendar-day-group:nth-child(31) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.1s;
  opacity: 0;
}
.calendar-day-group:nth-child(32) {
  transition-delay: 0.256s;
}
.loading .calendar-day-group:nth-child(32) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.2s;
  opacity: 0;
}
.calendar-day-group:nth-child(33) {
  transition-delay: 0.264s;
}
.loading .calendar-day-group:nth-child(33) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.3s;
  opacity: 0;
}
.calendar-day-group:nth-child(34) {
  transition-delay: 0.272s;
}
.loading .calendar-day-group:nth-child(34) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.4s;
  opacity: 0;
}
.calendar-day-group:nth-child(35) {
  transition-delay: 0.28s;
}
.loading .calendar-day-group:nth-child(35) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.5s;
  opacity: 0;
}
.calendar-day-group:nth-child(36) {
  transition-delay: 0.288s;
}
.loading .calendar-day-group:nth-child(36) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.6s;
  opacity: 0;
}
.calendar-day-group:nth-child(37) {
  transition-delay: 0.296s;
}
.loading .calendar-day-group:nth-child(37) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.7s;
  opacity: 0;
}
.calendar-day-group:nth-child(38) {
  transition-delay: 0.304s;
}
.loading .calendar-day-group:nth-child(38) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.8s;
  opacity: 0;
}
.calendar-day-group:nth-child(39) {
  transition-delay: 0.312s;
}
.loading .calendar-day-group:nth-child(39) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 3.9s;
  opacity: 0;
}
.calendar-day-group:nth-child(40) {
  transition-delay: 0.32s;
}
.loading .calendar-day-group:nth-child(40) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4s;
  opacity: 0;
}
.calendar-day-group:nth-child(41) {
  transition-delay: 0.328s;
}
.loading .calendar-day-group:nth-child(41) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4.1s;
  opacity: 0;
}
.calendar-day-group:nth-child(42) {
  transition-delay: 0.336s;
}
.loading .calendar-day-group:nth-child(42) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4.2s;
  opacity: 0;
}
.calendar-day-group:nth-child(43) {
  transition-delay: 0.344s;
}
.loading .calendar-day-group:nth-child(43) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4.3s;
  opacity: 0;
}
.calendar-day-group:nth-child(44) {
  transition-delay: 0.352s;
}
.loading .calendar-day-group:nth-child(44) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4.4s;
  opacity: 0;
}
.calendar-day-group:nth-child(45) {
  transition-delay: 0.36s;
}
.loading .calendar-day-group:nth-child(45) {
  transform: translate3d(10px, 0px, 0px);
  transition-delay: 4.5s;
  opacity: 0;
}

.calendar-day-group__number {
  cursor: pointer;
}
.disabled .calendar-day-group__number {
  cursor: not-allowed;
}

.calendar-day-group__circle {
  border: 2px solid;
  border-color: transparent;
  border-radius: 50%;
  padding: 6px;
  width: 37px;
  height: 37px;
  text-align: center;
}
.active .calendar-day-group__circle {
  border-color: var(--cssvar-primary);
}

.calendar-day-group__dot {
  position: absolute;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--cssvar-primary);
  left: 0;
  right: 0;
  margin: auto;
  display: none;
  bottom: 4px;
}
.busy .calendar-day-group__dot {
  display: block;
}

#calendar-loader {
  opacity: 1 !important;
  grid-column: 4;
}

#booking-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 2px;
  height: 232px;
}

#book-now-header {
  height: 50px;
}
#book-now-header img {
  height: 60%;
}

.flex-cnt.wrap--2.gap--1.no-stretch.scelta-slot, .wrap--2.gap--1.no-stretch.scelta-slot.bookings-archive-header, .wrap--2.gap--1.no-stretch.scelta-slot#book-now-header {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
  margin: 0 -27px 0 -27px;
  padding: 0 27px 13px 27px;
}

.flex-cnt.wrap--2.gap--1.no-stretch.scelta-slot button.button, .wrap--2.gap--1.no-stretch.scelta-slot.bookings-archive-header button.button, .wrap--2.gap--1.no-stretch.scelta-slot#book-now-header button.button {
  min-width: 150px;
}

