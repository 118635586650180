@use "sass:math";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700&display=swap");
@import "./assets/styles/variables";
@import "./assets/styles/mixins";
@import "./assets/styles/abstract";
@import "./assets/styles/forms";

// reset.css
// prettier-ignore
button,input:disabled{background-color:transparent}
button,
input {
  font-family: inherit;
  font-weight: inherit;
}
* {
  box-sizing: border-box;
  margin: 0;
}
a,
a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
  color: #e20613;
}
input {
  outline: 0;
  padding: 0;
}
button {
  border: none;
  font-size: inherit;
}
input[type="radio"] {
  margin-right: 5px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
img {
  max-width: 100%;
}

// base style
body {
  font: toCSSVar(font);
  color: toCSSVar(black);
  line-height: toCSSVar(lineHeight);
}

h1 {
  @extend .text--important;
  @extend .text--bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 28px;
}

h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}

hr {
  border: 1px solid toCSSVar(gray)Chiaro;

  &.primary {
    border: 1px solid toCSSVar(primary);
  }
}

//==============
// COMPONENTS
//==============
.icon-macchina {
  height: 87px;
}
.vibrate-1 {
  -webkit-animation: vibrate-1 0.9s linear 2s 3 both;
  animation: vibrate-1 0.9s linear 2s 3 both;
}
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
.terreno-macchina {
  background: #ebebeb;
  height: 20px;
  width: 90%;
  margin: -15px 5% -5px 5%;
  --mask: radial-gradient(
        18.89px at 50% calc(100% + 16px),
        #0000 calc(99% - 2px),
        #000 calc(101% - 2px) 99%,
        #0000 101%
      )
      calc(50% - 16px) calc(50% - 5px + 0.5px) / 32px 10px repeat-x,
    radial-gradient(
        18.89px at 50% -16px,
        #0000 calc(99% - 2px),
        #000 calc(101% - 2px) 99%,
        #0000 101%
      )
      50% calc(50% + 5px) / 32px 10px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}
.vibrate-2 {
  -webkit-animation: vibrate-2 0.7s linear 2s 3 both;
  animation: vibrate-2 0.7s linear 2s 3 both;
}
@-webkit-keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.fade-in {
  -webkit-animation: fade-in 3s cubic-bezier(0.39, 0.575, 0.565, 1) 2s both;
  animation: fade-in 3s cubic-bezier(0.39, 0.575, 0.565, 1) 2s both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bookings-archive-header-cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: toCSSVar(white);
}
.elenco_soste {
  padding-bottom: 80px;
  padding-top: 170px;
}

.div-targa input {
  text-transform: uppercase;
}

.select-slot * {
  display: none;
}