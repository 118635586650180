@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.bookings-archive-item {
  transition: all 0.08s ease-in-out;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
  opacity: 1;

  @for $i from 1 through 45 {
    &:nth-child(#{$i}) {
      transition-delay: 0.025s * $i;
    }

    .loading &:nth-child(#{$i}) {
      transform: translate3d(0px, 10px, 0px) scale3d(1, 0.55, 1);
      transition-delay: 0.025s * $i;
      opacity: 0;
    }
  }

  .elenco_soste-prog & {
    @extend .bg--xxLightGray;
  }

  .elenco_soste-effet & {
    @extend .bg--xLightGray;
  }
}

#loader-gif {
  width: 80px;
}
