@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.component-toast__backdrop {
    &.open {
        display: block;
    }
}
.component-toast {
    visibility: hidden;
    max-width: 400px;
    background-color: #fff;
    color: toCSSVar(black);
    text-align: center;
    border-radius: toCSSVar(defaultRadius);
    padding: 16px;
    position: fixed;
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    line-height: 20px;
    bottom: 0;
    vertical-align: middle;
    box-shadow: 1px 1px 10px 1px #00000057;

    &.open {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
        // -webkit-animation: fadein 0.5s, fadeout 0.5s 5.7s;
        -webkit-animation: fadein 0.25s;
        // animation: fadein 0.5s, fadeout 0.5s 5.7s;
        animation: fadein 0.25s;
        bottom: toCSSVar(toastBottom);
        vertical-align: middle;
        z-index: 10000;
    }

    @media only screen and (min-width: 401px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0 10% 0 10%;
    }
    @media only screen and (max-width: 400px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0 10% 0 10%;
    }
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: toCSSVar(toastBottom);
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: toCSSVar(toastBottom);
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: toCSSVar(toastBottom);
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: toCSSVar(toastBottom);
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

/* fix 20 novembre 2023 */
.open.component-toast .component-search-input .component-search-input__box {
    @media only screen and (min-width: 401px) and (max-width: 767px) {
        min-width: 245px !important;
    }
    @media only screen and (max-width: 400px) {
        min-width: 245px !important;
    }
}
