.form-input-group {
    label {
        @extend .text--small;
        color: toCSSVar(gray);
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        @extend .text--emphasis-2;
        @extend .text--bold;
        border: none;
        border-bottom: 2px solid toCSSVar(black);
        width: 100%;
        display: block;
        color: toCSSVar(black);
        border-radius: 0;
    }
}

.form-checkbox-group {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}
.form-checkbox-group__checkmark {
    position: absolute;
    top: -4px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid toCSSVar(xLightGray);

    &:after {
        content: "";
        position: absolute;
        display: none;

        left: 5px;
        top: -6px;
        width: 10px;
        height: 20px;
        border: solid toCSSVar(primary);
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked ~ &:after {
        display: block;
    }
}