@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.button {
    @include sausage;
    background-color: toCSSVar(primary);
    color: toCSSVar(white);
    text-transform: uppercase;
    position: relative;
    z-index: 5;
    @extend .text--emphasis-3;
    @extend .text--bold;

    &.inverse {
        background-color: toCSSVar(white);
        color: toCSSVar(primary);
        border: 2px solid toCSSVar(primary);
    }

    &[disabled] {
        background-color: toCSSVar(xLightGray);
    }

    transition: all 0.3s;
    &.loading {
        transition: all 0.4s, opacity 0.3s 0.3s;
        opacity: 0;
        width: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
        border: 2px solid toCSSVar(primary) !important;
        background: transparent !important;

        .btn-txt-container {
            -webkit-transition: opacity 0.15s;
            transition: opacity 0.15s;
            opacity: 0;
        }
    }

    &[type="submit"],
    &.inverse:not(.scelta-slot-btn) {
        // z-index: 99999;
        margin-bottom: 50px;
    }
}

// button spin animation
.lds-circle {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transition: opacity 0.15s;
    display: inline-block;
    transform: scale3d(0, 1, 1);
    .loading + & {
        transform: scale3d(1, 1, 1);
        transition: all 0.15s 0.3s;
        opacity: 1;
    }
}
.lds-circle > div {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: toCSSVar(primary);
    // background-image: url(../../assets/imgs/logo-icon.png);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center;
    animation: lds-circle 6s cubic-bezier(0, 1, 0.5, 0.5) infinite;
}
@keyframes lds-circle {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(3600deg);
    }
}
