@import "./_variables";

// segna il punto di rottura tra layout verticale e orizzontale
@mixin breakUp {
    @media (min-width: toCSSVar(breakpoint)) {
        @content;
    }
}

// segna il punto di rottura tra layout orizzontale e verticale
@mixin breakDown {
    @media (max-width: (toCSSVar(breakpoint) - 1px)) {
        @content;
    }
}

@mixin responsive {
    @include breakDown {
        &.responsive {
            @content;
        }
    }
}

// shaping & FX
@mixin circle($diameter) {
    border-radius: 50%;
    width: $diameter;
    height: $diameter;
}

@mixin sausage {
    width: 100%;
    height: 55px;
    border-radius: 28px;
}

@mixin mainMenu {
    height: toCSSVar(mainMenuHeight);
    background-color: toCSSVar(xxLightGray);
}

// in code: replace 
//      color(primary)
// with
//      toCSSVar(primary)
// https://stackoverflow.com/questions/51888205/any-way-to-use-css-variables-in-sass-functions-for-hsl-transparency
@function toCSSVar($var-name) {
    @return var(--cssvar-#{$var-name});
}
