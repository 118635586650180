@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

#login-page {
    min-height: 98vh;
    /* background: url(../../assets/imgs/macchine-in-fila.svg) repeat-x -40px 97%; */
}

.macchine-compass {
    overflow: hidden;
    margin-top: -13px;
    z-index: -1;
    position: relative;

    & img.macchine-backg.slide-in-left {
        min-height: 90px;
        min-width: 455px;
        margin: 0 0 0 -40px;
    }
}

/* ANIMAZIONE SLIDE - entrata a sinistra */
.slide-in-left {
	-webkit-animation: slide-in-left 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-left 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
} 
 @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  