@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.button-icon.muted {
    @include sausage;
    background-color: #DDDDDD;
    color: #555555;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
    @extend .text--emphasis-3;
    @extend .text--bold;

    img {
        height: 32px;
        margin-left: toCSSVar(gap);
        position: relative;
        top: 2px;
    }
}

.booking-detail-page {
    min-height: 100vh;
    background: url(../../assets/imgs/sfondo-macchine.svg) repeat top center;

    .booking-card {
        padding-top: 0;
        padding-bottom: 0;
    }
}