@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/abstract";

.calendar-day-group {
    position: relative;
    justify-content: center;
    display: flex;
    transition: all 0.1s ease-in-out;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;

    @for $i from 2 through 45 {
        &:nth-child(#{$i}) {
            transition-delay: 0.008s * $i;
        }

        .loading &:nth-child(#{$i}) {
            transform: translate3d(10px, 0px, 0px);
            transition-delay: 0.1s * $i;
            opacity: 0;
        }
    }
}
.calendar-day-group__number {
    @extend .text--emphasis-3;
    @extend .fg--black;
    cursor: pointer;

    .disabled & {
        @extend .fg--lightGray;
        cursor: not-allowed;
    }
}
.calendar-day-group__circle {
    border: 2px solid;
    border-color: transparent;
    border-radius: 50%;
    padding: 6px;
    width: 37px;
    height: 37px;
    text-align: center;

    .active & {
        border-color: toCSSVar(primary);
    }
}
.calendar-day-group__dot {
    position: absolute;
    @include circle(5px);
    background-color: toCSSVar(primary);
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    bottom: 4px;

    .busy & {
        display: block;
    }
}

#calendar-loader {
    opacity: 1 !important;
    grid-column: 4;
}

#booking-calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 2px;
    height: 232px;
}

#book-now-header {
    height: 50px;
    @extend .text--emphasis;
    @extend .flex-cnt;
    @extend .flex-cnt--anchor-antipodes;
    @extend .align--center--v;
    @extend .fg--white;

    img {
        height: 60%;
    }
}

.flex-cnt.wrap--2.gap--1.no-stretch.scelta-slot {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 0 -27px 0 -27px;
    padding: 0 27px 13px 27px;
}
.flex-cnt.wrap--2.gap--1.no-stretch.scelta-slot button.button {
    min-width: 150px;
}
